var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v("Users ("+_vm._s(_vm.totalUsers)+")")]),_c('v-spacer'),(_vm.hasCreatePermission)?_c('v-btn',{staticClass:"appbar__btn",attrs:{"color":"primary","height":"40px","right":"","to":{ name: 'user.create' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icons.add))]),_vm._v(" Add User ")],1):_vm._e(),_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"#383d3f","icon":"","right":"","disabled":_vm.loading},on:{"click":_vm.exportList}},on),[_c('v-icon',[_vm._v("mdi-export-variant")])],1)]}}])},[_c('span',[_vm._v("Export to Excel")])])],1),_c('div',{staticClass:"mx-4",attrs:{"id":"user-list","infinite-scroll-disabled":_vm.loading,"infinite-scroll-distance":"100"}},[_c('div',{staticClass:"appbar mt-7 mb-3"},[_c('v-tabs',[_c('v-tab',{attrs:{"disabled":_vm.defaultStatus !== 'active' && _vm.loading},on:{"change":function($event){_vm.defaultStatus = 'active'
            _vm.activeAction()}}},[_vm._v(" Active ")]),_c('v-tab',{attrs:{"disabled":_vm.defaultStatus !== 'blocked' && _vm.loading},on:{"change":function($event){_vm.defaultStatus = 'blocked'
            _vm.activeAction()}}},[_vm._v(" Deactivated ")]),_c('v-tab',{attrs:{"disabled":_vm.defaultStatus !== 'deleted' && _vm.loading},on:{"change":function($event){_vm.defaultStatus = 'deleted'
            _vm.activeAction()}}},[_vm._v(" Deleted ")]),_c('v-tab',{attrs:{"disabled":_vm.defaultStatus !== 'all' && _vm.loading},on:{"change":function($event){_vm.defaultStatus = 'all'
            _vm.activeAction()}}},[_vm._v(" All ")])],1),_c('v-text-field',{ref:"searchField",staticClass:"appbar__filter",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search name, email or phone number"},on:{"input":_vm.search},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"ml-10",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Bulk Actions ")])]}}])},[_c('v-list',_vm._l((_vm.actions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"server-items-length":_vm.totalUsers,"options":_vm.options,"loading":_vm.loading,"v-model":_vm.selected,"footer-props":{
        'items-per-page-options': [25, 50, 100, -1],
      },"sort-by":_vm.defaultFilter,"sort-desc":_vm.sortOrder,"hide-default-footer":_vm.loading},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch],"click:row":_vm.editItem,"update:sortBy":function($event){_vm.defaultFilter=$event},"update:sort-by":function($event){_vm.defaultFilter=$event},"update:sortDesc":function($event){_vm.sortOrder=$event},"update:sort-desc":function($event){_vm.sortOrder=$event}},scopedSlots:_vm._u([{key:"item.full_name",fn:function({ item }){return [_c('user-avatar',{staticClass:"mr-4",attrs:{"avatar":item.avatar,"size":"40"}}),_vm._v(" "+_vm._s(item.full_name)+" ")]}},{key:"item.property",fn:function({ item }){return [_vm._v(" "+_vm._s(item?.property?.name)+" ")]}},{key:"item.onboarded_at",fn:function({ item }){return [_c('v-checkbox',{attrs:{"disabled":"","readonly":"","hide-details":""},model:{value:(item.isOnboarded),callback:function ($$v) {_vm.$set(item, "isOnboarded", $$v)},expression:"item.isOnboarded"}})]}},{key:"item.building",fn:function({ item }){return [_vm._v(" "+_vm._s(item.buildingName)+" ")]}},{key:"item.blocked_at",fn:function({ item }){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('Status',{attrs:{"status":item.statusLabel}})],1)]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(item.addedDate)+" ")]}}])}),(!(_vm.users.length > 0) && !_vm.loading)?_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('p',[_vm._v("No match found.")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }